@import "_config.sass";

$home-padding: 20px;

.front #block-fs_helpers-freestar_ATF {
  margin-top: 1.5em;
  margin-bottom: 1.5em;
}

#block-fs_helpers-homepage_fold2 {
  margin: auto; // 0 10px 0;
  border: none;
  padding: 0; //background: #111;
  display: block;
  width: 100%;
  max-width: 1360px;
  // @include gradient(#000000, #222222);
  .block-inner {
    display: inline-block;
    width: 100%;
    padding: $padding*2 $padding $padding $padding;
    vertical-align: top;
    border-bottom: solid 4px #ccc;
    //padding-top: $home-padding;
  }
}

.front #main {
  border-top: solid 1px #ccc;
  padding-top: 0;
}

.homepage-features {
  float: left;
  width: 60%; //background-color: #eee;
  padding-right: $home-padding*2;

  .homepage-feature {
    position: relative;
    width: 30%;
    float: right;

    .inner {
      margin: 0; // 30px 0 0;
      position: relative; //background-color: #eee;
    }

    img {
      width: 100%;
      height: auto;
      vertical-align: middle;
    }

    a {
      text-decoration: none;
      color: #000;
    }

    .content {
      padding: 0.1em;
      //background-color: rgba(255, 255, 255, 0.9);
    }

    .category {
      //font-family: $header-family;
      text-transform: uppercase;
      font-weight: normal; //900;
      color: #999; //$bright-blue;
      font-size: 11px;
    }

    .title {
      //position: relative; //font-family: $header-family;
      //font-weight: bold;
      line-height: 1.1em;
      margin: 0.1em 0 1.1em 0;
      font-size: 16px; //line-height: 1.1em;
      //padding: 0; //@include text-shadow(0 1px 0 #000);
    }

    /*     .highlight {
      background-color: rgba(0, 0, 0, 0.6);
      padding: 0.2em 0.15em;
      line-height: 0.9em;
      display: inline-block;
    } */
  }

  #feature-1 {
    width: 70%;
    float: left;
    padding-right: $home-padding;

    .title {
      font-size: 35px;
    }

    .category {
      font-size: 14px;
      line-height: 1.2em;
    }

    .content {
      padding: 10px 0;
    }
  }

  &.has-live-broadcast {
    #feature-1 .inner {
      background-color: transparent;
    }
  }

  /* #feature-2,
  #feature-3 {
    width: 30%;
    float: left;
    .feature-title {
      font-size: 16px;
    }
  } */
}

.home-slider {
  clear: both;
  position: relative;

  .view-content {
    padding: 0 0 0.5em 0; //0.5em 0 2em 0;
    .views-field {
      padding: 0;
    }
  }

  //height: 100%;
  //max-height: 200px;
  .swiper-button {
    top: 35%;
    position: absolute;
    z-index: 20;
    background: #fff;
    padding: 0.9em;
    font-size: 10px;
    color: #ccc;
    cursor: pointer;
    @include transition(color 0.3s ease-out);

    &:hover {
      color: #000;
    }
  }

  .sw-button-prev {
    left: 0;
  }

  .sw-button-next {
    right: 0;
  }

  .swiper-button-disabled {
    display: none;
  }

  /* .swiper-slide{
    max-height: 100px;
  } */
  .node .photo-wrapper img,
  .field-name-field-lead-video img,
  .field-name-field-lead-image img,
  .field-name-field-photo img {
    max-height: 100%;
    height: 100% !important;
    width: auto !important;
    object-fit: cover;
  }

  .swiper-slide {
    //width: auto;
  }

  .swiper-slide .photo {
    height: 150px;

    a {
      display: inline-block;
      height: 100%;
      width: 100%;
    }
  }

  &.potd-slider {
    .swiper-slide .photo {
      height: 200px;
    }
  }

  /* .swiper-pagination{
    width: 100% !important;
    padding: 10px 0;
    transform: scale(1.3);
    .swiper-pagination-bullet{
      margin: 1px;
    }
  } */
  .swiper-scrollbar {
    height: 4px;
    margin: 5px 0;
    /*  &:hover{
      background-color: #ccc;
     } */
    cursor: pointer;
  }

  .swiper-scrollbar-drag {
    cursor: pointer;
    background-color: #ccc; //$bright-blue;
    &:hover {
      //transform: scale(2);
      /* height: 150%;
        margin-top: -2px; */
    }
  }

  .swiper {
    opacity: 0;
    @include transition(opacity 0.5s ease-out);
  }

  .swiper-initialized {
    opacity: 1;
  }
}

.featured-photos-wrapper {
  width: 100%;
  /* .photo-wrapper{
    img{
      width: 100%;
      height: 100%;
      display: inline-block;
    }
  } */
}

.homepage-community {
  width: 40%;
  float: left;
  padding-left: 0; //calc($home-padding/2);
  //border-left: solid 1px #ddd; //margin: 0 -5px;
  /* .featured-photos{
   position: relative;
      &::before {
        content: "";
        display: block;
        padding-bottom: calc(100% / 2.2);
        //background-color: #ff0000;
      }
       > :first-child {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
      }
    } */
  //background-color: #ddd;
  .view-more {
    float: right;
    font-size: 11px;
    font-weight: normal;

    a {
      text-decoration: none;
      color: #666;
    }
  }
}

.fold-block-subtitle {
  font-size: 12px;
  font-weight: bold;
  //font-family: $header-family;
  //padding-top: 10px;
  //font-weight: 900;
  text-transform: uppercase;
  line-height: 2em;
  color: #111;
}

.fold-block-title {
  font-family: $header-family;
  color: $bright-blue;
  font-size: 16px;
  line-height: 1.5em;
  text-transform: uppercase;
  font-weight: 600;
  border-bottom: solid 1px #ddd;
  margin-bottom: 10px; //margin-bottom: 3px;
  //margin: 0 5px 3px 5px;

}

.home-slider {
  position: relative;
  padding-bottom: 10px;

  .view-featured-photos {
    .views-field-field-date-1 {
      font-size: 11px;
      padding: 0.4em;
      background-color: rgba(0, 0, 0, 0.5);
      border-radius: 3px;
      font-family: $default-family;
      font-weight: normal;
    }
  }

  .manage {
    display: none !important;
  }

  .badge {
    width: 80px;
    height: 80px;
    background-image: url(../images/fs-potd-badge2.png);
    background-size: cover;
  }

  button.splide__arrow {
    background-color: transparent !important;
    opacity: 0.5;

    &:hover {
      opacity: 1;
    }

    &:disabled {
      opacity: 0.2;
    }

    svg {
      fill: #999;
    }
  }

  .splide__arrow--prev {
    left: -30px;
  }

  .splide__arrow--next {
    right: -30px;
  }

  .splide__pagination {
    text-align: left;
    width: 100%;
    display: block;
    padding: 0;
    bottom: -20px;
  }

  .splide__pagination__page {
    margin: 2px;
    background: #ccc;
  }

  .splide__pagination__page:hover {
    background: #999;
  }

  .splide__pagination__page.is-active {
    background: $bright-blue;
    transform: scale(1.2);
    opacity: 1;
  }
}

#quicktabs-bookmarks,
#quicktabs-homepage_articles {
  .item-list {
    border-bottom: solid 1px #ddd;
    margin-bottom: 15px;
    height: 40px;
  }

  ul.quicktabs-tabs {
    font-family: $header-family;
    width: 100%;
    display: inline-block;
    float: none;
    letter-spacing: $header-spacing;
    padding: 0;
    margin-bottom: -1px;
    vertical-align: bottom;
    overflow-y: hidden;

    li {
      font-size: 16px;
      font-weight: bold; //text-transform: uppercase;
      //margin-bottom: -1px;
      margin-right: 15px;
      vertical-align: bottom; //margin-bottom: -1px;
      line-height: 2.5em;

      i {
        vertical-align: middle;
        line-height: inherit;
        color: #aaa;
        font-size: 1em;
      }

      a {
        background: transparent;
        color: #333;
        @include border-radius(0);
        font-weight: inherit;
        padding: 0; // 0 15px 0;
        line-height: unset;

        &:hover {
          color: $bright-blue;
        }
      }

      &.active {
        a {
          background-image: url(../images/nav-marker.png) !important;
          background-repeat: no-repeat;
          background-position: center bottom -6px;
        }

        a,
        i {
          color: $bright-blue; //background: transparent;
          font-weight: inherit;
        }
      }
    }
  }

  #quicktabs-bookmarks {
    .item-list {
      border: none;
    }

    ul.quicktabs-tabs {
      font-family: $header-family;
      border: none;
      width: 100%;
      display: inline-block;
      float: none;
      letter-spacing: normal; //$header-spacing;
      margin: 0;
      padding: 0;

      li {
        font-size: 14px;
        font-weight: normal;
        text-transform: normal;
        margin: 0px; //default-button()
        margin-right: 1em;
        border-right: solid 1px #ddd;
        padding-right: 1em;

        &:last-child {
          border: 0;
        }

        i {
          vertical-align: middle;
          line-height: inherit;
          color: #aaa;
          font-size: 1em;
        }

        a {
          background: transparent !important;
          color: #333;
          @include border-radius(0);
          font-weight: inherit;
          padding: 0.5em 0;
        }

        &.active {
          background-image: none;

          a,
          i {
            color: $bright-blue;
            background: transparent;
            font-weight: inherit;
          }
        }
      }
    }
  }
}

// wide //$wide-min-width
@media only screen and (min-width: 1410px) {
  #block-fs_helpers-homepage_fold2 {
    .block-inner {
      padding-right: 0;
      padding-left: 0; //$padding*2 $padding $padding $padding;
    }
  }
  .homepage-features {
    .homepage-feature {
      .title {
        font-size: 18px;
      }

      .inner {
        //margin: 0 30px 0 0;
      }

      /* .feature-title {
        font-size: 37px;
        padding: 20px;
      } */
    }

    /* #feature-2,
    #feature-3 {
      .feature-title {
        font-size: 20px;
      }
    } */
  }
  .home-slider {
    .sw-button-prev {
      left: -35px;
    }

    .sw-button-next {
      right: -35px;
    }
  }
  #quicktabs-homepage_articles {
    .item-list {
      height: 52px;
      margin-left: -$padding;
    }

    ul.quicktabs-tabs {

      li {
        font-size: 21px;
        background-position: center 25px;
        margin: 0 15px;
      }
    }
  }
}

// /end wide.
// narrow
@media only screen and (max-width: $tablet-max-width) and (min-width: $mobile-max-width) {
  .homepage-community {
    border: none;
    /* .featured-photos{
      //position: relative;
      &::before {
        display: none;
      }
        > :first-child {
        position: inherit;
      }
    } */
    /* .featured-photos,
    .featured-groups {
      width: 40%;
      float: right;
      margin: 0;
      padding: 0;
      .view-popular-discussions {
        padding-left: 0;
      }
    } */
    /* .featured-photos {
      padding-left: 10px;
      padding-right: 5px;
      width: 60%;
    } */
  }
  .homepage-community,
  .homepage-features {
    width: 100%;
    margin: 0;
    padding: 0;
    clear: both;
    float: none;

  }
  .homepage-features {

    .homepage-feature {
      width: 33.2%;

      .inner {
        margin: 0;
      }

      .feature-title {
        font-size: 18px;
        line-height: 1em;
      }
    }

    #feature-1 {
      width: 66.7%;
      float: left;
    }
  }
  #quicktabs-homepage_articles {
    .item-list {
      height: 42px;
    }

    ul.quicktabs-tabs {
      li {
        font-size: 14px !important;
        margin-right: 10px;
        background-position: center 20px;
      }
    }
  }

  .fold-block-title {
    margin-left: 0;
    margin-right: 0;
    padding-top: 15px;
  }
}

// small
@media only screen and (max-width: $mobile-max-width),
only screen and (max-device-width: $mobile-max-width) {
  #block-fs_helpers-homepage_fold2 .block-inner {
    padding: $padding calc($padding / 2);
  }
  .homepage-features .homepage-feature .inner {
    //background-color: transparent;
  }
  .homepage-features {
    //margin: 0 -5px;
    padding: 0;
    float: none;
    display: inline-block;
    width: 100%;
    line-height: 1em;

    .homepage-feature {
      width: 100% !important;
      padding: 0 !important;
      display: inline-block;

      .inner {
        //margin: 10px 0;
        padding: 1em 0;
        border-bottom: solid 1px #eee;
        width: 100%;
        display: inline-block;
      }

      .lead-image-aspect-wrapper {
        //max-width: 150px;
        float: left;
        width: 130px;
      }

      .category {
        font-size: 11px !important;
      }

      .content {
        margin: 0 0 0 130px;
        padding: 0 calc($padding / 2) !important;
      }

      h2.title {
        font-size: 18px !important;
        margin-bottom: 0;
      }

      &:last-child {
        .inner {
          border: none;
        }
      }
    }
  }

  .fold-block-title {
    // margin-left: 0;
    // margin-right: 0;
    padding-top: 5px;
    font-size: 16px;
    line-height: 1.1em;
    margin-bottom: 5px;
  }

  .homepage-community {
    //width: 100%;
    margin: 0;
    padding: 0;
    clear: both;
    float: none;
    display: block;
    width: auto;
    border: none; //margin-right: -5px;
    //padding-top: 10px;
    //height:;

    .potd .gallery_item,
    .gallery_item {
      //margin: 0 !important;
      //padding: 2px !important;
    }

    /* .potd .gallery_item{
      padding: 4px !important;
    } */
  }
  .home-slider {
    //margin: 0 -10px;
  }
  .homepage-features {
    .on-air-message {
      padding-left: 5px;
    }

    &.has-live-broadcast {
      padding-top: 20px;

      .inner {
        max-height: 100% !important;
        overflow: visible !important;
      }

      #feature-1 {
        display: block;
        width: 100%;
        float: none;

        .inner {
          padding: 1em 0;
        }

        .video-wrapper {
          //margin-bottom: 1em;
        }
      }
    }
  }


  #quicktabs-homepage_articles {
    .item-list {
      height: 35px;
    }

    ul.quicktabs-tabs {
      li {
        font-size: 14px;
        padding: 0 7px;
        margin-right: 0; //padding
        // background-position: center 20px;
        a {
          background-position: center bottom -9px !important;
        }

        i {
          display: none;
        }
      }

      .last {
        display: none;
      }
    }
  }
}

// this is for landscape phones.
@media only screen and (min-width: 500px) and (max-width: $mobile-max-width) {
  .homepage-features {
    .homepage-feature {
      .inner {
        //max-height: 220px;
      }
    }
  }
}
