// Variables
// ---------
// Use the same color all over the place? Need to do
// some math with height and width and text size?
// Sass supports variables as well as basic math
// operations and many useful functions.
//
// For complete documentation:
// http://sass-lang.com/docs/yardoc/file.SASS_REFERENCE.html#variables_
//
// Available variables:
// SASS:				http://sass-lang.com/docs/yardoc/file.SASS_REFERENCE.html#variables_
// Bourbon:				http://bourbon.io/docs/

// Colour variables
$grey: #ccc
$grey_light: lighten($grey, 20%)
$grey_dark: darken($grey, 20%)

// Margin/padding variables
$margin: $gutter
$padding: $gutter

$default-family: "Helvetica Neue", helvetica, Arial, sans-serif
//$header-family: "Archivo Narrow",Arial,"Helvetica Neue",helvetica,sans-serif
//$header-family: "Exo",Arial,"Helvetica Neue",helvetica,sans-serif
$header-family: "Heebo","Helvetica Neue",  helvetica, Arial, sans-serif
//$header-family: "Roboto",Arial,"Helvetica Neue",helvetica,sans-serif
$fa-family: 'Font Awesome 6 Pro'
$nav-height: 50px
$fbColWidth: 420px
$nav-height-wide: 90px

$bright-blue: #1680d3
$dark-grey: #747474
//56626b
$lighter-grey: #6e7880
$medium-grey: #cdd0d3
$light-grey: #ebeced
$off-black: #202121
$darker-blue: #0a63a8
$pale-blue: #eaf6ff
$delete-red: #a30c17
$bright-red: #ff0000
$green: #090
$dark-green: #060
$light-black: #313131
$yellow: #f3d74b
$header-bg-color: #f7f7f7
$spin-speed: 0.7s

$profile-banner-height: 305px

//$header-spacing: -0.042em
$header-spacing: -0.025em

$form-label-width: 140px
$avatar-width: 50px

$ui-light: $light-black
$ui-dark: $off-black
$sprite: '../images/sprite2.png'
$body-font-color: #666

$license-width: 1000px


//$ui-light: $lighter-grey
//$ui-dark: $dark-grey
//$sprite: '../images/sprite.png'
