// Mixins
// ------
// Mixins allow you to define styles that can be re-used
// throughout the stylesheet without needing to resort to
// non-semantic classes like .float-left. Mixins can also
// contain full CSS rules, and anything else allowed
// elsewhere in a Sass document. They can even take
// arguments which allows you to produce a wide variety
// of styles with very few mixins.
//
// For complete documentation:
// Sass mixins:		http://sass-lang.com/docs/yardoc/file.SASS_REFERENCE.html#mixins
// Bourbon mixins: 	http://bourbon.io/docs/

// Import variables for use in Mixins.
@import _variables.sass

// Preferred version of clearfix
@mixin clearfix()
  &:after
    display: table
    clear: both
    content: ""

@mixin outer-container()
  @include clearfix()
  max-width: $max-width + 320
  margin-left: auto
  margin-right: auto

@mixin transition($transition...)
  -moz-transition:    $transition
  -o-transition:      $transition
  -webkit-transition: $transition
  transition:         $transition

// Rounded corners mixin.
@mixin border-radius($top: 10px, $right: $top, $bottom: $top, $left: $right)
  border-radius: $top $right $bottom $left
  //-moz-border-radius: $top $right $bottom $left
  //-webkit-border-top-left-radius: $top
  //-webkit-border-top-right-radius: $right
  //-webkit-border-bottom-right-radius: $bottom
  //-webkit-border-bottom-left-radius: $left

// Text shadow
@mixin text-shadow($value: 0 1px 0 #ffffff)
  -moz-text-shadow: $value
  -webkit-text-shadow: $value
  -o-text-shadow: $value
  text-shadow: $value

@mixin box-shadow-double($one: none)
  -moz-box-shadow: $one, $one
  -webkit-box-shadow: $one, $one
  box-shadow: $one, $one

@mixin box-shadow($hor:none, $ver:0, $blur:0, $size:0, $color:0, $inset:'')
  @if $hor == 'none'
    -moz-box-shadow: $hor
    -webkit-box-shadow: $hor
    box-shadow: $hor
  @else
    @if $inset == ''
      -moz-box-shadow: $hor $ver $blur $size $color
      -webkit-box-shadow: $hor $ver $blur $size $color
      box-shadow: $hor $ver $blur $size $color
    @else
      -moz-box-shadow: $hor $ver $blur $size $color $inset
      -webkit-box-shadow: $hor $ver $blur $size $color $inset
      box-shadow: $hor $ver $blur $size $color $inset

@mixin gradient($color1: #3859A8, $color2: #5190EF)
  /* IE10 Consumer Preview */
  background-image: -ms-linear-gradient(bottom, $color1 0%, $color2 100%) !important
  /* Mozilla Firefox */
  background-image: -moz-linear-gradient(bottom, $color1 0%, $color2 100%) !important
  /* Opera */
  background-image: -o-linear-gradient(bottom, $color1 0%, $color2 100%) !important
  /* Webkit (Safari/Chrome 10) */
  background-image: -webkit-gradient(linear, left bottom, left top, color-stop(0, $color1), color-stop(1, $color2)) !important
  /* Webkit (Chrome 11+) */
  background-image: -webkit-linear-gradient(bottom, $color1 0%, $color2 100%) !important
  /* W3C Markup, IE10 Release Preview */
  background-image: linear-gradient(to top, $color1 0%, $color2 100%) !important

@mixin gradient_deg($color1: #3859A8, $color2: #5190EF, $dir: 90deg, $start: 0%)
  background: $color1
  background: -moz-linear-gradient($dir, $color1 $start, $color2 100%)
  background: -webkit-linear-gradient($dir, $color1 $start, $color2 100%)
  background: linear-gradient($dir, $color1 $start, $color2 100%)

@mixin columns($count: 2, $gap: auto)
  -moz-column-count: $count
  -webkit-column-count: $count
  column-count: $count
  column-gap: $gap
  -moz-column-gap: $gap
  -webkit-column-gap: $gap

@mixin no-rollovers()
  tr:hover td
    background: none

@mixin no-active()
  td.active
    background: none

@mixin transform_scale($size: none, $origin: top left)
  @if $size != 'none'
    -moz-transform: $size
    -webkit-transform: $size
    -o-transform: $size
    -ms-transform: $size
    transform: $size
  @else
    -moz-transform: scale($size)
    -webkit-transform: scale($size)
    -o-transform: scale($size)
    -ms-transform: scale($size)
    transform: scale($size)
  -webkit-transform-origin: $origin
  -moz-transform-origin: $origin
  -ms-transform-origin: $origin
  -o-transform-origin: $origin
  transform-origin: $origin

@mixin scale($size: none, $origin: top left)
  @if $size != 'none'
    -moz-transform: $size
    -webkit-transform: $size
    -o-transform: $size
    -ms-transform: $size
    transform: $size
  @else
    -moz-transform: scale($size)
    -webkit-transform: scale($size)
    -o-transform: scale($size)
    -ms-transform: scale($size)
    transform: scale($size)
  -webkit-transform-origin: $origin
  -moz-transform-origin: $origin
  -ms-transform-origin: $origin
  -o-transform-origin: $origin
  transform-origin: $origin

@mixin oneline()
  overflow: hidden
  text-overflow: ellipsis
  white-space: nowrap

@mixin grey-button($fontsize, $height)
  margin: 0 5px 0 0 !important
  padding: 0 15px
  font-size: $fontsize
  color: #777
  line-height: $height
  height: $height
  text-decoration: none
  @include  border-radius(3px)
  @include gradient(#ddd, #FFF)
  @include box-shadow(0, 1px, 1px, 0, rgba(0,0,0,0.1))
  @include text-shadow(0 1px 0 #fff)
  border: solid 1px #ccc
  &:hover
    background-color: #ddd
    @include gradient(#ccc, #eee)
    border-color: #bbb
    color: #333

@mixin default-button()
  margin: 0 1em
  text-align: center
  background: $green
  @include gradient(#eee, #f7f7f7)
  @include box-shadow(inset, 0, 1px, 0, #fff)
  border: solid 1px #ddd
  font-weight: bold
  padding: 0 10px
  display: inline-block
  color: #333
  &:hover
    background: #ddd
    @include gradient(#ddd, #ddd)
    @include box-shadow(inset, 0, 1px, 0, #ccc)
    //@include gradient($dark-green, $green)
    //@include box-shadow(inset, 0, 1px, 0, #1cb512)
  &.disabled,
  &:disabled
    @include gradient($medium-grey, #ddd)
    color: #FFF
    @include box-shadow(inset, 0, 1px, 0, #f7f7f7)
    border: solid 1px #ccc
    cursor: default

@mixin green-button()
  margin: 0 1em
  text-align: center
  background: $green
  @include gradient($green, #43c716)
  @include text-shadow(-1px -1px 0 rgba(0,0,0,0.2))
  @include box-shadow(inset, 0, 1px, 0, #5ae42d)
  border: solid 1px #128c08
  font-weight: bold
  padding: 0 10px
  display: inline-block
  &:hover
    background-color: $dark-green
    @include gradient($dark-green, $green)
    @include box-shadow(inset, 0, 1px, 0, #1cb512)
  &.disabled,
  &:disabled
    @include gradient($medium-grey, #ddd)
    color: #FFF
    @include box-shadow(inset, 0, 1px, 0, #f7f7f7)
    border: solid 1px #ccc
    cursor: default

@mixin blue-button()
  text-decoration: none
  border: solid 1px $darker-blue
  color: #fff
  @include border-radius(3px)
  @include box-shadow(inset, 0, 1px, 0, #56acf0)
  &:hover
    background: $darker-blue !important
    @include box-shadow(inset, 0, 1px, 0, #298ddb)
  &.disabled,
  &:disabled
    @include gradient($medium-grey, #ddd)
    color: #FFF
    @include box-shadow(inset, 0, 1px, 0, #f7f7f7)
    border: solid 1px #ccc
    cursor: default
    opacity: 0.7


@mixin red-button()
  text-decoration: none
  border: solid 1px #c20e1c
  color: #fff
  @include border-radius(3px)
  @include gradient($delete-red, #c20e1c)
  @include text-shadow(-1px -1px 0 rgba(0,0,0,0.2))
  @include box-shadow(inset, 0, 1px, 0, #db1c2b)
  &:hover
    background: #c20e1c !important
    @include box-shadow(inset, 0, 1px, 0, #db1c2b)
  &.disabled,
  &:disabled
    @include gradient($medium-grey, #ddd)
    color: #FFF
    @include box-shadow(inset, 0, 1px, 0, #f7f7f7)
    border: solid 1px #ccc
    cursor: default
    opacity: 0.7

@mixin trans-black_gradient()
  background: unquote('-moz-linear-gradient(bottom, rgba(0,0,0,0.9) 0%, rgba(0,0,0,0) 45%, rgba(0,0,0,0) 100%)')
  background: unquote('-webkit-gradient(linear, left bottom, left top, color-stop(0%,rgba(0,0,0,0.9)), color-stop(45%,rgba(0,0,0,0)), color-stop(100%,rgba(0,0,0,0)))')
  background: unquote('-webkit-linear-gradient(bottom, rgba(0,0,0,0.9) 0%,rgba(0,0,0,0) 45%,rgba(0,0,0,0) 100%)')
  background: unquote('-o-linear-gradient(bottom, rgba(0,0,0,0.9) 0%,rgba(0,0,0,0) 45%,rgba(0,0,0,0) 100%)')
  background: unquote('-ms-linear-gradient(bottom, rgba(0,0,0,0.9) 0%,rgba(0,0,0,0) 45%,rgba(0,0,0,0) 100%)')
  background: unquote('linear-gradient(to top, rgba(0,0,0,0.9) 0%,rgba(0,0,0,0) 45%,rgba(0,0,0,0) 100%)')
  filter: unquote("progid:DXImageTransform.Microsoft.gradient( startColorstr='#80000000', endColorstr='#00000000',GradientType=0 )")

@mixin trans-black_gradient-top()
  //background: unquote('-moz-linear-gradient(top, rgba(0,0,0,0.9) 0%, rgba(0,0,0,0) 85%, rgba(0,0,0,0) 100%)')
  //background: unquote('-webkit-gradient(linear, left top, left top, color-stop(0%,rgba(0,0,0,0.9)), color-stop(85%,rgba(0,0,0,0)), color-stop(100%,rgba(0,0,0,0)))')
  //background: unquote('-webkit-linear-gradient(top, rgba(0,0,0,0.9) 0%,rgba(0,0,0,0) 85%,rgba(0,0,0,0) 100%)')
  //background: unquote('-o-linear-gradient(top, rgba(0,0,0,0.9) 0%,rgba(0,0,0,0) 85%,rgba(0,0,0,0) 100%)')
  //background: unquote('-ms-linear-gradient(top, rgba(0,0,0,0.9) 0%,rgba(0,0,0,0) 85%,rgba(0,0,0,0) 100%)')
  //background: unquote('linear-gradient(to bottom, rgba(0,0,0,0.9) 0%,rgba(0,0,0,0) 85%,rgba(0,0,0,0) 100%)')
  //filter: unquote("progid:DXImageTransform.Microsoft.gradient( startColorstr='#80000000', endColorstr='#00000000',GradientType=0 )")
  background: unquote('-moz-linear-gradient(top,  rgba(0,0,0,0.65) 0%, rgba(0,0,0,0) 90%)')
  background: unquote('-webkit-linear-gradient(top,  rgba(0,0,0,0.65) 0%,rgba(0,0,0,0) 90%)')
  background: unquote('linear-gradient(to bottom,  rgba(0,0,0,0.65) 0%,rgba(0,0,0,0) 90%)')
  filter: unquote("progid:DXImageTransform.Microsoft.gradient( startColorstr='#a6000000', endColorstr='#00000000',GradientType=0 )")

@mixin tags()
  display: inline-block
  list-style: none
  margin-right: 3px
  margin-bottom: 3px
  margin-left: 0
  font-size: 12px
  a
    background-color: #eee
    @include border-radius(3px)
    display: block
    line-height: normal
    text-decoration: none
    padding: 5px 10px
    color: #888
    &:hover
      color: #fff
      background-color: $bright-blue

//@function strip-units($number)
  //@return calc($number / calc($number * 0) + 1)
  //@if type-of($number) == 'number' and not unitless($number)
    //@return calc($number / ($number * 0 + 1))

  //@return $number

//@mixin blur($size)
  //filter: blur($size)
  //-webkit-filter: blur($size)
  //-moz-filter: blur($size)
  //-o-filter: blur($size)
  //-ms-filter: blur($size)
  //filter: url(#blur)
  //filter: unquote('progid: DXImageTransform.Microsoft.Blur(PixelRadius="')strip-units($size)unquote('")')
  //zoom: $size

@mixin blur( $px: 0 )
  -webkit-filter: blur(#{$px}px)
  -o-filter: blur(#{$px}px)

  -moz-filter: blur(#{$px}px)

  -ms-filter: blur(#{$px}px)

  filter: url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\'><filter id=\'blur\'><feGaussianBlur stdDeviation=\'#{$px}\' /></filter></svg>#blur")
  //filter:progid:DXImageTransform.Microsoft.Blur(PixelRadius='#{$px}')

@mixin zoom($size)
  -moz-transform: scale($size)
  -moz-transform-origin: 50% 50%
  -o-transform: scale($size)
  -o-transform-origin: 50% 50%
  -webkit-transform: scale($size)
  -webkit-transform-origin: 50% 50%
  transform: scale($size)
  transform-origin: 50% 50%

@mixin center-vertical($position: relative)
  position: $position
  top: 50%
  -webkit-transform: perspective(1px) translateY(-50%)
  -ms-transform: perspective(1px) translateY(-50%)
  transform: perspective(1px) translateY(-50%)

@mixin center-vertical-reset()
  position: relative
  top: inherit
  -webkit-transform: none
  -ms-transform: none
  transform: none

@mixin center-contents()
  display: -webkit-box
  display: -ms-flexbox
  display: -webkit-flex
  display: flex
  -webkit-box-pack: center
  -ms-flex-pack: center
  -webkit-justify-content: center
  justify-content: center
  -webkit-box-align: center
  -ms-flex-align: center
  -webkit-align-items: center
  align-items: center


@mixin center-contents-left()
  display: -webkit-box
  display: -ms-flexbox
  display: -webkit-flex
  display: flex
  -webkit-box-pack: center
  -ms-flex-pack: center
  -webkit-justify-content: left
  justify-content: left
  -webkit-box-align: center
  -ms-flex-align: center
  -webkit-align-items: center
  align-items: center

@mixin text-dots()
  white-space: nowrap
  overflow: hidden
  text-overflow: ellipsis

@mixin ellipsis()
  white-space: nowrap
  overflow: hidden
  text-overflow: ellipsis

@mixin multiline-clamp($lines: 3)

  /*! autoprefixer: ignore next */
  -webkit-box-orient: vertical

  display: -webkit-box
  -webkit-line-clamp: $lines
  overflow: hidden
  text-overflow: ellipsis
  white-space: normal

@mixin no-multiline-clamp($lines: 3)
  /*! autoprefixer: ignore next */
  -webkit-box-orient: initial

  display: initial
  //-webkit-line-clamp: $lines
  overflow: initial
  text-overflow: initial
  white-space: initial

